import './sentryJs';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import App from './app';
import store from './store';

// TODO: must remove from main.css, now not work lazy import for pannellum-react if not imported
require('pannellum-react/es/pannellum/css/pannellum.css');
require('pannellum-react/es/pannellum/css/style-textInfo.css');
require('pannellum-react/es/pannellum/css/video-js.css');


import('SASS/_landing.sass');
import('SASS/_single_lot.sass');
import('SASS/_other_pages.sass');
import('SASS/_media_queries.sass');
require('../../config/landing.icons.js');
require('../../config/category.icons.js');
require('../../config/ico.icons.js');

const rootElement = document.getElementById('root');


ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    rootElement
);

