import { DEFAULT_DISCOUNT_PERCENT, FILES_API, LANG_LIST } from 'CONFIG';
import {
    API_PREFIXES,
    CARD_MONTHS,
    CARD_YEARS, COLOR_THEME,
    EXCLUDED_CATEGORIES_IN_CREATE,
    EXCLUDED_CATEGORIES_MASONRY_TEXT
} from 'CONST';
import crc32 from 'MODULES/crc32';
import { formatDate } from 'MODULES/pp_moment';
import { API_URLS } from 'CONFIG/API_URLS';
// import Moment from 'moment';


export const preventDefaultFunction = (e) => { e.preventDefault(); };


export const calculatePercents = (number, percents = DEFAULT_DISCOUNT_PERCENT) => (((number / 100) * percents).toFixed(2));


export const getBookmarkIcon = isBookmarked => (
    isBookmarked ? ' pp-heart bookmark-remove pb-2 pt-1 pe-2' : ' pp-heart-o bookmark-add pb-2 pt-1 pe-2'
);


export const getInactiveLanguages = lang => LANG_LIST.filter(item => (item !== lang));


export const checkFeedSourceLink = (link) => {
    const pattern = new RegExp('^(http|https)://', 'i');

    return pattern.test(link);
};

export const isValueEmailOrPhone = (value) => {
    const pattern = new RegExp('^((?=[^\\s]{4,255}$).+@.+\\.[\\S]+|\\d{1,3} \\d{8}|.\\d{1,3} \\d{8}|.\\d{1,3}\\d{8}|\\d{8})$');

    return pattern.test(value);
};

export const convertXMLCategoryPath = category => category.replace('&gt;&gt;', '>');

export const isCategoryExcludedInCreate = categoryId => EXCLUDED_CATEGORIES_IN_CREATE.includes(categoryId);
export const isCategoryTextExcludedInMasonry = categoryId => EXCLUDED_CATEGORIES_MASONRY_TEXT.includes(categoryId);


export const calculatePriceAfterDiscount = (price, discount) => parseFloat(price - (discount / 100) * price).toFixed(2);

export const getCardExpireYears = () => Array.from({ length: CARD_YEARS }, (_, index) => new Date().getFullYear() + index);
export const getCardExpireMonths = () => Array.from({ length: CARD_MONTHS }, (_, index) => index + 1);
export const convertDateForStatistics = (locale, format, date) => new Intl.DateTimeFormat(locale, format).format(new Date(date));
export const switchTheme = theme => (theme === COLOR_THEME.light ? COLOR_THEME.dark : COLOR_THEME.light);
export const getUserHashCrc32 = (user) => crc32(`${user.id}_${formatDate(user.createDate, false, true)}`);

export const isEndDateApproaching = (endDate, isClosed) => {
    const currentDate = new Date();
    if (isClosed) {
        // need for publish button, maybe lot not expired
        return endDate > currentDate;
    }

    return currentDate >= (endDate - 7 * 24 * 60 * 60 * 1000);
};

export const getStaticFileUrl = (lang, id) => `${FILES_API}/${lang}/${API_PREFIXES.user}${API_URLS.STATIC_FILE(id)}`;
