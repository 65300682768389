export const FILTER_WHITELIST_PARAMS = [
    'filterValues',
    'fV',
    'query',
    'similar',
    'ownerType',
    'minPrice',
    'maxPrice',
    'priceType',
    'priceTypes',
    'orderColumn',
    'orderDirection',
    'page',
    'itemsPerPage',
    'categories',
    'region',
    'street',
    'building',
    'action',
    'countOnly',
    'area',
    'xmlLinks',
    'owners',
    'period',
    'visibilities',
    'identifier'
];
