import React from 'react';
import { Link } from 'react-router-dom';

import { PP_URLS } from 'CONFIG/PP_URLS';
import { checkNested } from 'MODULES/checkNested';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';

const EmptyMy = ({ i18n, location }) => (
    <div className="col-12">
        <div className="empty-page">
            <i className="pp-landing pp-my-lots icon-lg" />
            {checkNested(location, ['state', 'tabIndex'], LOT_VISIBILITY.published.id) === LOT_VISIBILITY.published.id && <h5>{i18n.__('You haven\'t created any classified.')}</h5>}
            <p>{i18n.__('Here you can find all your classifieds. Active and inactive ones. Use button "Create classified" to publish your first classified. One classified a month always is for free.')}</p>
            <div id="xml_upload">
                <Link
                    to={{
                        pathname: PP_URLS.FEED,
                        state: { setAdd: true }
                    }}
                    className="btn btn-secondary mb-1"
                >
                    <i className="pp-landing pp-plus-circle" />
                    {i18n.__('Add feed')}
                </Link>
                <Link to={PP_URLS.CREATE} className="btn btn-primary ms-1 mb-1">
                    <i className="pp-landing pp-plus-circle" />
                    {i18n.__('Create classified')}
                </Link>
            </div>
        </div>
    </div>
);

export default EmptyMy;
