import {
    API_PREFIXES,
    LOT_STATUSES_FOR_MSG_ACTIVE,
    LOT_STATUSES_FOR_MSG_ARCHIVE,
    PARTICIPANT_TYPE,
    TIME_ONE_MONTH
} from 'CONST';
import { FLUSH_ALL_MESSAGES, FLUSH_ALL_THREADS, GET_MESSAGING_LOT_LIST } from 'ACTIONS';
import { API_URLS } from 'CONFIG/API_URLS';
import { apiAction } from 'ACTIONS/api';
import { CLASSIFIEDS_PER_PAGE, MESSAGING_API, MESSAGING_CONF } from 'CONFIG';
import { checkNested } from 'MODULES/checkNested';
import { calculateTimeRemain, getUnixTimeStamp } from 'MODULES';
import { setIsFetching } from 'ACTIONS/ui/setIsFetching';
import { getThreadLot } from 'ACTIONS/messaging/getThreadLot';
import size from 'lodash/size';

const apiPrefix = API_PREFIXES.msg;

export function getLotList(type = PARTICIPANT_TYPE.company, i18n, timestamp = null, callback) {
    return (dispatch) => {
        dispatch({ type: FLUSH_ALL_MESSAGES });
        dispatch({ type: FLUSH_ALL_THREADS });
        // dispatch({ type: FLUSH_ALL_LOTS });

        dispatch(setIsFetching(GET_MESSAGING_LOT_LIST, true));
        const timeStampParam = timestamp ? { from_time: parseFloat(timestamp) - 1 } : {};
        let url = API_URLS.THREADS;
        if (type === PARTICIPANT_TYPE.company) {
            url = API_URLS.THREADS_COMPANY;
        }
        dispatch(apiAction(
            {
                url,
                auth: true,
                host: MESSAGING_API,
                apiPrefix,
                data: {
                    ...timeStampParam,
                    limit: CLASSIFIEDS_PER_PAGE,
                    order: MESSAGING_CONF.messaging_sort,
                    pointer_direction: MESSAGING_CONF.messaging_sort
                },
                onSuccess: d => () => {
                    const content = checkNested(d, ['content'], {});
                    if (callback) {
                        callback();
                    }
                    if (content.totalCount && content.data.length > 0) {
                        let payload = {};
                        const payloadUnread = {};
                        const payloadActive = {};
                        const payloadArchive = {};
                        const lastThread = content.data[content.data.length - 1];
                        const lastThreadTimeStamp = getUnixTimeStamp(lastThread.updatedAt);
                        new Promise((resolve) => {
                            content.data.forEach((data) => {
                                new Promise((resolve) => {
                                    dispatch(getThreadLot(data.itemId, i18n, type, getUnixTimeStamp(data.updatedAt), resolve));
                                }).then((lot) => {
                                    // all threads that older than one month goes to archive
                                    if (data.totalMessages > 0 && data.newMessages > 0 && !LOT_STATUSES_FOR_MSG_ARCHIVE.includes(lot.adState.visibility) && !calculateTimeRemain(Date.now(), data.updatedAt, TIME_ONE_MONTH)) {
                                        payloadUnread[getUnixTimeStamp(data.updatedAt)] = { ...data, lot };
                                    }
                                    if (data.totalMessages > 0 && LOT_STATUSES_FOR_MSG_ACTIVE.includes(lot.adState.visibility) && data.newMessages === 0 && !calculateTimeRemain(Date.now(), data.updatedAt, TIME_ONE_MONTH)) {
                                        payloadActive[getUnixTimeStamp(data.updatedAt)] = { ...data, lot };
                                    }
                                    if (data.totalMessages > 0 && calculateTimeRemain(Date.now(), data.updatedAt, TIME_ONE_MONTH) || LOT_STATUSES_FOR_MSG_ARCHIVE.includes(lot.adState.visibility)) {
                                        payloadArchive[getUnixTimeStamp(data.updatedAt)] = { ...data, lot };
                                    }
                                    payload = { ...payload, [getUnixTimeStamp(data.updatedAt)]: data };
                                    if (size(payload) === content.data.length) resolve({ payloadUnread, payloadActive, payloadArchive });
                                });
                            });
                        }).then((response) => {
                            dispatch({
                                type: GET_MESSAGING_LOT_LIST,
                                payloadUnread: response.payloadUnread,
                                payloadActive: response.payloadActive,
                                payloadArchive: response.payloadArchive,
                                amount: content.totalCount,
                                owner: type === PARTICIPANT_TYPE.company ? 'company' : 'personal',
                                lastThreadTimeStamp
                            });
                        });
                    }
                },
                label: GET_MESSAGING_LOT_LIST
            }
        ));
    };
}
