import React, { memo, Suspense, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { STORAGE } from 'CONFIG';
import PoweredBy from 'COMPONENTS/powered_by';
import MobileFooter from 'COMPONENTS/footer/mobile';
import { stringExistsIn } from 'MODULES/validations';
import FollowOnSocial from 'COMPONENTS/follow_on_social';
import FooterGdpr from 'COMPONENTS/footer_gdpr';
import Storage from 'HOC/storage';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { useHistory } from 'react-router';
import { isMobile } from 'react-device-detect';

const year = new Date().getFullYear();

const Footer = (props) => {
    const { i18n, location } = props;
    const [isOpenGdpr, setIsOpenGdpr] = useState(false);
    const history = useHistory();
    const removeGdprDiv = () => {
        const gdprDiv = document.getElementById('startup_gdpr');
        if (gdprDiv) {
            gdprDiv.remove();
        }
    };
    useEffect(() => {
        const gdpr = document.getElementById('gdpr-modal-preferences');
        if (gdpr) {
            gdpr.addEventListener('click', () => {
                setIsOpenGdpr(true);
                removeGdprDiv();
            });
        }

        const gdprAccept = document.getElementById('gdpr-modal-allow');
        if (gdprAccept) {
            gdprAccept.addEventListener('click', () => {
                Storage.set(STORAGE.gdpr, true);
                initializeTagManager();
                removeGdprDiv();
            });
        }
    });

    const closeGdprModal = () => {
        setIsOpenGdpr(false);
    };
    if (stringExistsIn('/qr/', location)) {
        return null;
    }
    if (isMobile) {
        return (
            <Suspense fallback={null}>
                {isOpenGdpr
                && <FooterGdpr closeGdprModal={closeGdprModal} isOpenGdpr={isOpenGdpr} i18n={i18n} key="gdpr" />}
                <MobileFooter key="modal" year={year} {...props} />
            </Suspense>
        );
    }
    const navigate = (event) => {
        const siteUrl = window.env.NODE_ENV === '1';
        if (event.target.tagName === 'A') {
            const href = event.target.getAttribute('href');
            if (href.indexOf('mailto:') === -1) {
                event.preventDefault();
                history.push(href.replace(siteUrl, ''));
            }
        }
    };
    return (
        <div
            onClick={(e) => {
                navigate(e);
            }}
            className="d-print-none container-fluid footer-wrapper"
            data-nosnippet="true"
        >
            <Suspense fallback={null}>
                { isOpenGdpr && <FooterGdpr closeGdprModal={closeGdprModal} isOpenGdpr={isOpenGdpr} i18n={i18n} />}
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-lg-2 order-1 order-lg-0">
                            <div className="text-center">
                                <PoweredBy />
                                <div className="mt-1 fs-075">
                                    ©
                                    {year}
                                    {' '}
                                    {i18n.__('LLC Inbokss')}
                                </div>
                            </div>
                        </div>
                        <div
                            className="col-12 col-md-4 d-none d-lg-block"
                            dangerouslySetInnerHTML={{ __html: i18n.__('Maintenance and technical support for this site is provided by inbox.lv team. Feel free to %scontact us%s if you have found a bug.', ['<span class="line-height-1">', `<a href="${PP_URLS.CONTACTS()}" class="text-decoration-underline">`, '</a>', '</span>']) }}
                        />
                        <div className="col-12 col-lg-6">
                            <div className="d-grid grid-template-columns-2">
                                <div className="d-flex mt-1 me-2">
                                    <i className="pp-landing pp-hdd-o" />
                                    <Link className="d-block text-decoration-underline" to={PP_URLS.USER_DATA_POLICY()}>{i18n.__('User data policy')}</Link>
                                </div>
                                <div className="d-flex mt-1 me-2">
                                    <i className="pp-landing pp-a-file-text-o" />
                                    <Link className="d-block text-decoration-underline" to={PP_URLS.RULES()}>{i18n.__('Terms And Conditions')}</Link>
                                </div>
                                <div className="d-flex mt-2 me-2">
                                    <i className="pp-landing pp-question-circle-o" />
                                    <Link className="d-block text-decoration-underline" to={PP_URLS.INFO()}>{i18n.__('Frequently Asked Questions')}</Link>
                                </div>
                                <div className="d-flex mt-2 me-2">
                                    <i className="pp-landing pp-envelope-o" />
                                    <Link className="d-block text-decoration-underline" to={PP_URLS.CONTACTS()}>{i18n.__('Contact Administrators')}</Link>
                                </div>
                                <div className="d-flex mt-1 me-2">
                                    <FollowOnSocial i18n={i18n} key={1} header />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Suspense>
        </div>
    );
};

export default memo(Footer);
