import { LANG_LIST } from 'CONFIG';
import now from 'lodash/now';
import { stringExistsIn } from 'MODULES/validations';
import { checkNested } from 'MODULES/checkNested';

export const wrap = (x, min, max) => (x < min ? (x - min) + max + 1 : ((x > max) ? (x - max) + min - 1 : x));

export const isTrueKeysInMap = map => (
    Object.keys(map)
        .map(k => map[k])
        .find(t => t)
);

export const hasArrayValue = (array, value) => {
    let hasValue = false;

    array.forEach((item) => {
        if (item === value) hasValue = true;
    });

    return hasValue;
};

export function isArrayValuesEqual(arr) {
    if (!arr.length) {
        return false;
    }
    for (let i = 1; i < arr.length; i++) {
        if (arr[i] !== arr[0]) return false;
    }

    return true;
}

export const calculateTimeRemain = (now, then, delay, difference = false) => {
    if (difference) {
        return delay - ((new Date(now).getTime() - new Date(then).getTime()) / 1000);
    }
    return new Date(now).getTime() - new Date(then).getTime() > delay;
};


export const mapArrayToObjectPropId = (array, prop = 'id', takeKey = null) => (
    array.reduce((acc, x) => {
        acc[x[prop]] = takeKey ? { [takeKey]: x[takeKey] } : x;
        return acc;
    }, {})
);

export const mapObjectToArrayPropId = (object, props = 'id') => {
    // can be replaced with lodash  sortBy(values(state.createNewPp.files), ['sequence'])
    const arr = [];
    Object.values(object).forEach((item) => {
        arr[item[props]] = item;
    });
    return arr;
};

export const filterObjectKey = (object, key) => {
    const newData = Object.keys(object)
        .reduce((newObj, item) => {
            if (item.toString() !== key.toString()) {
                newObj[item] = object[item];
            }
            return newObj;
        }, {});
    return newData;
};

export const mapArrayToObjectWithProp = (array, prop) => array
    .reduce((acc, x) => {
        acc[x[prop].id] = x;
        return acc;
    }, {});

export const mapArrayIdToObjectStringKey = (array, prefix = 'id-') => {
    const newObj = {};
    array.forEach((item) => {
        newObj[`${prefix}${item.id}`] = item;
    });
    return newObj;
};

export const mapObjectPropToObjectKey = (obj, prop = 'id') => {
    const newObj = {};

    Object.values(obj).forEach((item) => {
        newObj[item[prop]] = item;
    });

    return newObj;
};

export const filterObjectFromPassedArray = (array, prop, key = 'name', newKey = false) => {
    /*
    * array - data array
    * prop - data for filter array
    * key - key for object
    * newKey - use if you need to split in some array value
    */
    let obj = {};

    if (array) {
        array.forEach((item) => {
            if (item[key] === prop && !newKey) {
                obj = item;
            } else if (item[key] === prop && newKey) {
                if (!obj[key]) {
                    obj = { ...obj, [item[newKey]]: item };
                } else {
                    obj = { ...obj, [item[newKey]]: { ...obj[item[newKey]], item } };
                }
            }
        });
    }

    return obj;
};

export const isExpectedUrl = (url, expected) => {
    let urlMatches = false;
    if (url === expected) {
        urlMatches = true;
    }
    if (url.length === (expected.length + 1) && url[url.length - 1] === '/') {
        if (expected === url.substr(0, url.length - 1)) {
            // urls match but the actual url has a forward slash at the end
            urlMatches = true;
        }
    }

    if (!urlMatches) {
        // check if perhaps languages don't match but the url is correct
        const pathParams = url.split('/');
        let urlHasLangParam = false;
        for (const i in LANG_LIST) {
            if (LANG_LIST[i] === pathParams[1]) {
                // actual url has a valid lang param
                urlHasLangParam = true;
            }
        }

        if (stringExistsIn(expected, pathParams)) {
            urlMatches = true;
        }

        if (urlHasLangParam) {
            const expectedParams = expected.split('/');
            const actualParamAmount = pathParams.length;
            const expectedParamAmount = expectedParams.length;
            if (pathParams[2]) {
                urlMatches = true;
                for (let i = 2; i < actualParamAmount; i++) {
                    // compare other actual url params with expected url params
                    if (expectedParams[i] && pathParams[i]) {
                        if (expectedParams[i] !== pathParams[i]) {
                            urlMatches = false;
                            break;
                        }
                    } else if (i === (actualParamAmount - 1)) {
                        // this is the last expected url param
                        if (expectedParamAmount === (actualParamAmount - 1)) {
                            // actual url params have one additional param than expected
                            if (pathParams[i] === '') {
                                // this means that the last character is a forward slash
                                break;
                            } else {
                                urlMatches = false;
                                break;
                            }
                        }
                    }
                }
            }
        }
    }
    return urlMatches;
};

export const validateHash = (hash, length, exec) => {
    const paramPattern = (length) ? `[a-zA-Z0-9]{${length}}` : '^[a-zA-Z0-9]*$';
    const regexPattern = new RegExp(paramPattern);
    if (regexPattern.test(hash)) {
        if (exec) return regexPattern.exec(hash)[0];
        return hash;
    }

    return null;
};

export const validateSearch = (query) => {
    let preparedParams = null;

    if (query.charAt(0) === '?') {
        preparedParams = query.slice(1);
    }

    return preparedParams;
};

export const floatNumberWithSpaces = (x) => {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (parts[1] && parts[1].length === 1) parts[1] += 0;
    return parts.join('.');
};

export const floatNumberForPrice = (x) => {
    const parts = x.toString().split('.');
    const hasDecimals = parts[1] && parts[1].length > 0;

    if (parseInt(parts[0], 10) >= 10000) {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }

    if (hasDecimals) {
        if (parts[1] === '00') {
            return parts[0];
        } if (parts[1] === '0') {
            parts.pop();
        }
    }

    return parts.join('.');
};

export const getUnixTimeStamp = (date) => {
    if (!date) {
        return parseInt((now() / 1000).toFixed(0), 10);
    }
    return new Date(date).getTime() / 1000;
};

export const cutString = (text, strlen, joinString = ' ...') => {
    if (text && text.length > strlen) {
        return text.trim().substring(0, strlen).split(' ').slice(0, -1)
            .join(' ') + joinString;
    }
    if (text && text.length) {
        return text;
    }
    return '';
};

export const getParamsFromUrl = (string, searchParam) => {
    const params = new URLSearchParams(string);
    const result = params.get(searchParam);
    return result;
};

export const serializeFormData = (data, schema) => {
    let obj = {};
    Object.keys(schema.properties).forEach((field) => {
        if (schema.properties[field].type === 'object' && (!data[field] || Object.keys(data[field]).length === 0)) {
            const serializeObject = serializeFormData(data, schema.properties[field]);
            obj = { ...obj, [field]: serializeObject };
        } else if (schema.properties[field].widget === 'region' && data[field]) {
            const { region } = data[field];
            obj = { ...obj, [field]: region };
        } else {
            const value = data && (data[field] || data[field] === false) ? data[field] : null;

            if (checkNested(value, 'min')) {
                let newValue = {};
                if (value.min) {
                    newValue = { ...newValue, min: value.min };
                }
                if (value.max) {
                    newValue = { ...newValue, max: value.max };
                }
                obj = { ...obj, [field]: newValue };
            } else if (value || value === false) {
                obj = { ...obj, [field]: value };
            }
        }
    });
    return obj;
};

export const urlBase64ToUint8Array = (base64String) => {
    /* eslint-disable no-useless-escape */
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const arrayBufferToBase64 = (buffer) => {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
};

export const getDigestHashFromPath = (pathname) => {
    const regex = new RegExp('digest\\/(\\w+)');
    return regex.exec(pathname)[1];
};
export { filterId } from 'CONST';

export const getModalFromHash = (hash) => {
    if (hash.includes('payment')) {
        return 'premium';
    }
    if (!hash.includes('transaction_uuid')) return hash.split('&')[0].replace('#', '');
    if (hash.includes('paymentSuccess') || hash.includes('paymentFailed')) return '';
    return hash.split('&')[0].replace('#', '') || '';
};
