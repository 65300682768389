import {
    SWITCH_LANG,
    REPORTS_TYPE,
    PAGE_TIMESTAMP,
    PREPARE_SIMPLE_FORMS,
    PREPARE_DYNAMIC_FORMS,
    FORM_CONFIG,
    GET_STATIC_PAGE,
    API_OFFLINE,
    GET_HOME_PARTNERS,
    GET_PARTNERS_BY_CATEGORIES,
    SWITCH_OFF_MESSAGING,
    SAVE_LOCATION_HISTORY,
    API_PAYMENT_NEED,
    GET_WATCH_ID, GET_DELIVERY_POINTS
} from 'ACTIONS';
import { API_TYPE } from 'CONST';
import Storage from 'HOC/storage';
import { STORAGE } from 'CONFIG';

import { checkNested } from 'MODULES/checkNested';

const INITIAL_STATE = (lang, jsons) => {
    let selectedLang = Storage.get('language');
    if (window.location.href.indexOf(`${window.location.host}/ru`) !== -1) {
        selectedLang = 'ru';
        Storage.set('language', 'ru');
    } else if (!selectedLang) {
        selectedLang = __DEFAULT_LANG__;
    }
    return ({
        watchToken:  Storage.get(STORAGE.watch),
        jsons,
        selectedLang,
        forms: {},
        reports: null,
        staticPages: {},
        homePartners: [],
        partners: {},
        apiStatus: { msg: true },
        api_offline: { [API_TYPE.msg]: [] },
        locationHistory: {},
        deliveryPoints: {}
    });
};

export default function (prevState, action, cookies, jsons, path) {
    const state = prevState || INITIAL_STATE(checkNested(cookies, 'language'), jsons, path);
    switch (action.type) {
        case GET_WATCH_ID:
            return { ...state, watchToken: action.data };
        case SAVE_LOCATION_HISTORY:
            return { ...state,
                locationHistory: { ...state.locationHistory,
                    [action.payload.location]: {
                        ...checkNested(state.locationHistory, [action.payload.location], {}), [action.payload.key]: action.payload } } };
        case SWITCH_LANG:
            return { ...state, selectedLang: action.payload };
        case REPORTS_TYPE:
            return { ...state, reports: action.payload };
        case SWITCH_OFF_MESSAGING:
            return { ...state, apiStatus: { ...state.apiStatus, msg: false } };
        case PAGE_TIMESTAMP:
            return { ...state, pageTimestamp: action.payload };
        case PREPARE_SIMPLE_FORMS:
            return {
                ...state,
                forms: action.payload
            };
        case GET_HOME_PARTNERS:
            return { ...state, homePartners: action.payload };
        case GET_PARTNERS_BY_CATEGORIES:
            return { ...state, partners: { ...state.partners, [action.payload.category]: action.payload.data } };
        case PREPARE_DYNAMIC_FORMS:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    lv: {
                        ...state.forms.lv,
                        ...action.payload
                    },
                    ru: {
                        ...state.forms.ru,
                        ...action.payload
                    }
                }
            };
        case FORM_CONFIG:
            return {
                ...state,
                forms: {
                    ...state.forms,
                    [action.language]: {
                        ...state.forms[action.language],
                        [action.formName]: {
                            ...state.forms[action.language][action.formName],
                            [action.formType]: action.payload
                        }
                    }
                }
            };
        case API_OFFLINE:
            return {
                ...state,
                api_offline: {
                    ...state.api_offline,
                    [action.payload.api]: action.payload.time ? [...state.api_offline[action.payload.api], action.payload.time] : []
                }
            };
        case API_PAYMENT_NEED:
            return {
                ...state,
                apiNeedPayment: action.payload
            };
        case GET_DELIVERY_POINTS:
            return {
                ...state,
                deliveryPoints: { ...state.deliveryPoints, ...action.payload }
            };
        case GET_STATIC_PAGE:
            return {
                ...state,
                staticPages: {
                    ...state.staticPages,
                    [action.page]: action.payload
                }
            };
        default:
            return state;
    }
}
