import React from 'react';
import { AmountLoader } from 'COMMON/loader';
import classnames from 'classnames';
import { categoryClass } from 'MODULES/categoryClass';
import { getParent } from 'MODULES/getParent';
import { checkNested } from 'MODULES/checkNested';
import { catColor } from 'MODULES/catColor';

const CategoryIcon = ({ currentCategory, isFetchingAmount, amount, categoryName, filters }) => {
    const parents = getParent(currentCategory, true);
    return (
        <div
            id="breadcrumb-parent-icon"
            className={classnames('category-icon icon', {
                [catColor[parents[0].parents ? parents[0].parents[0].id : parents[0].id]]: true
            })}
        >
            <i className={
                categoryClass(checkNested(currentCategory, 'parents.1.id'), checkNested(currentCategory, 'level'))
                || categoryClass(checkNested(currentCategory, 'id'), checkNested(currentCategory, 'level'))
                || categoryClass(checkNested(parents, '1.id'), checkNested(currentCategory, 'level'))
            }
            />
            <div className="category-title">
                {categoryName}
                {' '}
                {isFetchingAmount ? AmountLoader : amount !== undefined && `(${amount})`}
            </div>
            {!filters && <i className="pp-ico pp-angle-down" />}
        </div>
    );
};


export default CategoryIcon;
