import React, { useState } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import { checkNested } from 'MODULES/checkNested';
import { LOT_VISIBILITY } from 'MODULES/LOT_VISIBILITY';
import Button from 'COMMON/button';
import ShareSocial from 'COMPONENTS/lot/socialShare';
import { cutString } from 'MODULES';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { MODALS } from 'MODULES/MODALS';
import { showModalWithData } from 'ACTIONS/ui/showModalWithData';
import PpCompareButton from 'COMPONENTS/lot/pp_compareButton';
import PpBookmarkButton from 'COMPONENTS/lot/pp_bookmarkButton';
import { useHistory, useRouteMatch } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { categoriesGetPpByIdSelector } from 'MODULES/reselect/selectors/categories';
import { PP_URLS } from 'CONFIG/PP_URLS';
import Storage from 'HOC/storage';

const LotActions = (props) => {
    const {
        i18n,
        myReview,
        isOwner,
        shareTitle,
        shareImage
    } = props;
    const history = useHistory();
    const match = useRouteMatch();
    const ppId = match.params.ppid;
    const pp = useSelector((state) => categoriesGetPpByIdSelector(state, ppId));
    const dispatch = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);
    const url = decodeURIComponent(window.location.href);
    const title = shareTitle || i18n.__('Check out this classified on “Pērc un Pārdod”');
    const ppImages = (pp && pp.files.length) ? pp.files[0].file : null;
    const file = shareImage || ppImages;
    const isVideo = file && file.outputType === 'video';
    const image = file ? buildImgUrl(file, isVideo ? 'thumbnail' : 'thumb') : null;
    const handleReviewModal = () => {
        if (Storage.get('login') !== '1') {
            toggle();
            setTimeout(() => {
                history.push(PP_URLS.SIGNIN);
            }, 200);
        }
        return dispatch(showModalWithData(pp.id, MODALS.review, true));
    };


    return (
        <div className="lot__actions ms-auto d-flex">
            <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle className="btn-only-icon">
                    <i className="pp-ico pp-share-square-o pt-1 pt-lg-0" />
                </DropdownToggle>
                <DropdownMenu>

                    {checkNested(pp, 'adState.visibility') === LOT_VISIBILITY.published.id && !isOwner && (
                        <Button
                            additionalClass="btn btn-sm share-feedback py-4"
                            id="review-classified"
                            data-test="review-classified"
                            secondary
                            disabled={myReview}
                            icon="pp-ico pp-star-o"
                            title={myReview ? i18n.__('Already reviewed this lot') : i18n.__('Share your review')}
                            onClick={(e) => {
                                e.preventDefault();
                                handleReviewModal();
                            }}
                        />
                    )}
                    <ShareSocial
                        title={title}
                        image={image}
                        url={url}
                        i18n={i18n}
                        body={cutString(checkNested(pp, ['adTexts', 0, 'text']), 80)}
                    />
                </DropdownMenu>
            </Dropdown>
            <PpCompareButton i18n={i18n} pp={pp} />

            {!isOwner && (
                <PpBookmarkButton
                    i18n={i18n}
                    key="ppBookmarkButton"
                />
            )}
            <AnchorLink offset="70" href="#contacts">
                <button className="btn-only-icon d-lg-none">
                    <i className="pp-ico pp-phone" />
                </button>
            </AnchorLink>
        </div>
    );
};

export default LotActions;
