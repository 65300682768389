import React, { useEffect } from 'react';
import { Col, Container, Row } from 'reactstrap';
import PpList from 'CONTAINERS/pp_list';
import { API_URLS } from 'CONFIG/API_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import DiscountProductsFilters from 'COMPONENTS/discount_products/discount_products_filters';
import size from 'lodash/size';
import omit from 'lodash/omit';
import { pickFilterValues } from 'MODULES/filters';
import { getParamsFromSearch } from 'MODULES/getParamsFromSearch';
import { checkNested } from 'MODULES/checkNested';
import merge from 'lodash/merge';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';
import Breadcrumb from 'COMPONENTS/breadcrumb/breadcrumb';
import store from 'STORE';
import { reducer as formReducer } from 'redux-form';


const DiscountProducts = ({ i18n }) => {
    const history = useHistory();
    const location = useLocation();
    useEffect(() => {
        store.injectReducer('form', formReducer);
    }, [0]);
    const paramsFromSearch = getParamsFromSearch(location.search);

    let discount = {};

    if (paramsFromSearch.maxDiscount || paramsFromSearch.minDiscount) {
        discount = { from: paramsFromSearch.minDiscount, to: paramsFromSearch.maxDiscount };
    }


    const initialFilterValues = { discountValue: discount, ownerType: paramsFromSearch.ownerType };


    return (
        <Container>
            <Breadcrumb
                title={i18n.__('Discount products')}
                icon="pp-ico pp-home"
                leftColumnClass="col-12"
                rightColumnClass=""
                i18n={i18n}
                ppType={PP_TYPE.discount}
                hasSortButtons
                disableListChange
            />
            <Row>
                <Col md={4} lg={3} xl={3} xxl={3}>
                    <DiscountProductsFilters
                        initialValues={initialFilterValues}
                        onChange={(value) => {
                            const values = value;
                            const mergedValues = omit(values, ['sort']);
                            const queryParams = pickFilterValues(mergedValues);
                            const oldparams = getParamsFromSearch(window.location.search);
                            const newParam = (size(values) === 1 && checkNested(values, 'multipleCategoryString') && !size(queryParams))
                                ? merge(oldparams, values)
                                : queryParams;
                            const queryString = qs.stringify(newParam);
                            history.replace({ search: queryString });
                        }}
                        i18n={i18n}
                    />
                </Col>
                <Col md={8} lg={9} xl={9} xxl={9}>
                    <PpList
                        row
                        i18n={i18n}
                        url={API_URLS.DISCOUNT_LOTS()}
                        ppType={PP_TYPE.discount}
                    />

                </Col>
            </Row>


        </Container>
    );
};

export default DiscountProducts;
