import {
    GET_PAYMENT_METHODS,
    CLEAR_PAYMENTS_STATE,
    GET_PAYMENT_STATUS,
    SUBSCRIPTION_TEMPLATES,
    SET_USER_CONTACTS_FEE,
    SET_TOLL_CATEGORY_FEE,
    SET_SUGGEST_FEE,
    SET_BOOST_TO_TOP_FEE,
    SET_TIK_TOK_FEE, SET_HIGHLIGHT_FEE
} from 'ACTIONS';
import { checkNested } from 'MODULES/checkNested';
import { COSTS } from 'CONST';

const INITIAL_STATE = {
    paymentMethods: {},
    paymentStatus: {},
    transactionId: null,
    templates: null,
    userContactsFee: COSTS.userContactsFee,
    tollCategoryFee: null,
    lotSuggestFee: null,
    lotBoostFee: null,
    lotTikTokFee: null,
    lotHighlightFee: null
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_PAYMENT_METHODS:
            return {
                ...state,
                paymentMethods: {
                    ...state.paymentMethods,
                    [action.credits]: checkNested(action.payload, ['content', 'methods'], action.payload.methods)
                },
                transactionId: action.payload.transactionId
            };
        case CLEAR_PAYMENTS_STATE:
            return { ...INITIAL_STATE, templates: state.templates };
        case GET_PAYMENT_STATUS:
            return { ...state, paymentStatus: action.payload };
        case SUBSCRIPTION_TEMPLATES:
            return { ...state, templates: action.payload };
        case SET_USER_CONTACTS_FEE:
            return { ...state, userContactsFee: Number(action.ppServiceFee) };
        case SET_TOLL_CATEGORY_FEE:
            return { ...state, tollCategoryFee: action.tollCategoryFee };
        case SET_SUGGEST_FEE:
            return { ...state, lotSuggestFee: action.lotSuggestFee };
        case SET_BOOST_TO_TOP_FEE:
            return { ...state, lotBoostFee: action.lotBoostFee };
        case SET_TIK_TOK_FEE:
            return { ...state, lotTikTokFee: action.lotTikTokFee };
        case SET_HIGHLIGHT_FEE:
            return { ...state, lotHighlightFee: action.lotHighlightFee };
        default:
            return state;
    }
}
