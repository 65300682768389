/* Time constants */

import { CATEGORIES } from 'MODULES/CATEGORIES';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { MODALS } from 'MODULES/MODALS';

export const AVAILABLE_HEADERS = {
    CONTENT_TYPE_FORM: { 'Content-Type': 'application/x-www-form-urlencoded' }
};

export const API_PREFIXES = {
    api: '',
    msg: 'api/v1/',
    user: 'api_user/v1/'
};

export const XML_STATUS = {
    ok: 0,
    inProgress: 3

};

export const CATEGORY_STATUS = {
    comingSoon: 4
};

export const TIME_ONE_SECOND = 1000;
export const TIME_ONE_AND_A_HALF_SECOND = 1500;
export const TIME_THREE_SECONDS = TIME_ONE_SECOND * 3;
export const TIME_FIVE_SECONDS = TIME_ONE_SECOND * 5;
export const TIME_TWENTY_SECONDS = TIME_ONE_SECOND * 20;
export const TIME_THIRTY_SECONDS = TIME_ONE_SECOND * 30;
export const TIME_ONE_MINUTE = TIME_ONE_SECOND * 60;
export const TIME_ONE_HOUR = TIME_ONE_MINUTE * 60;
export const TIME_TWO_HOURS = TIME_ONE_HOUR * 2;
export const TIME_EIGHT_HOURS = TIME_ONE_HOUR * 8;
export const TIME_ONE_DAY = TIME_ONE_HOUR * 24;
export const TIME_ONE_MONTH = TIME_ONE_DAY * 30;
export const TIME_ONE_YEAR = TIME_ONE_DAY * 365;
export const TIME_ONE_WEEK = TIME_ONE_DAY * 7;

export const DEBOUNCE_FILTER_TIMEOUT = 800;

export const CONTACT_HASH_LENGTH = 32;
export const PP_HASH_LENGTH = 16;
export const FILTER_HASH_LENGTH = 16;
export const INVITE_HASH_LENGTH = 36;
export const SHOW_PARTNER_AFTER_CAT_INDEX = {
    firstCarousel: 3,
    secondCarousel: 5
};

export const USER_STATUS = {
    new: 1,
    trusted: 2,
    hidden: 4,
    auto_hidden: 5,
    blocked: 6,
    deleted: 7
};

export const USER_COMPANY_PERMISSIONS = {
    none: 100,
    watch: 101,
    edit: 102
};

export const USER_COMPANY_ROLES = {
    admin: 1,
    publisher: 3
};

export const API_TYPE = {
    msg: 'msg',
    api: 'api'
};

export const CONTACT_TYPES = {
    PHONE: 1,
    EMAIL: 2,
    GOOGLE: 3,
    FACEBOOK: 4,
    DRAUGIEM: 6,
    INBOX: 7
};

export const DEFAULT_CONTACT_TYPE = CONTACT_TYPES.EMAIL;

export const SEARCH_ROUTES = {
    combined: '/combined',
    autocomplete: '/categories/autocomplete',
    lots: '/lots',
    categories: '/categories',
    filters: '/lots/filters',
    suggestion: '/suggestion'
};

export const SEARCH_TYPES = {
    filters: 'filters',
    lots: 'lots',
    categories: 'categories',
    autocomplete: 'autocomplete',
    combined: 'combined',
    suggestion: 'suggestion'
};

// Invoice statuses
export const INVOICE_STATUS = {
    PENDING: 1,
    PAID: 2,
    CANCELED: 3,
    REFUSED: 4
};

// My company statuses
export const COMPANY_STATUS = {
    NEW: 1,
    PENDING_REVIEW: 2,
    REJECTED: 3,
    APPROVED: 4
};

// SUBSCRIPTION PROPERTIES
export const SUBSCRIPTION_PROPERTIES = {
    simultaneously_active_items: 1,
    item_duration: 2,
    company_max_members: 3,
    item_credits: 4
};

// SUBSCRIPTION TYPE
export const SUBSCRIPTION_TYPE = {
    daily: 2,
    every_item: 1
};

// DEFAULT SUBSCRIPTION

export const DEFAULT_SUBSCRIPTION = 1;

// REPORTS TYPE
export const REPORT_TYPE = {
    LOT: 'lot',
    USER: 'user',
    COMPANY: 'company',
    LOT_CLOSING: 'lot_closing'
};

export const FILTER_VISIBILITY = {
    top: 1,
    showMore: 2,
    hidden: 3
};

export const DATE_DISPLAY_FORMAT = 'MM/YYYY';

export const FULL_DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';


export const EMPTY_PICTURE = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAVgAAAD8BAMAAADAuKE/AAAAMFBMVEX////MzMzl5eX8/PzU1NTh4eHOzs7+/v7t7e3x8fHR0dHp6enY2Njc3NzQ0ND6+vr1rpbDAAACzElEQVR42u3cMWsUQRjG8Zf3kCNYPUMiRhKZsbC+KoXdYRERC1NJShVsbNLYm8JWsPADBPwAVinsAn4BySews7SwEgvvEpKZJHt7x93szTvH82sP9v4su7c7A/cKERERERERERERERERERERERER1a734SkW82X/QJajf4zFPZHl+IMcTmUZ1o6Qw/pvWYK/yOOxdK9/gjy2pXu3kct76dwj5LIrnTtELpvSuT3ksiGdG2Lk7UAW0TvGyLp07ggjX3PcpfekcxgbyGJ6GJPOYczIURjLWMYydq6v6X3+/ubZ/qCK2N5DjL0a1BD7Eed+VRD7DhdemI/t/4hrlAPrsbcQPbcee4jorvHY3k9E9we2YxUpbzt2B6nXtmM/IbVpO3YPqQ3bsSdIbUmzNRuxQ6TWpVnwJmJfInVHGilcPbEB8BZiZ7kMFICzEDvLDRYw4g3EzvDTpRhzBmJneCgEnPHlY6c/bhXnXPnY6S8y4fLD4rFTXxEVF1zx2Kkv3wGXfPHYKcsaReSKx05ZMAYkvJheiitSrnhs6yZHwBW+eGzL9pHiKlc8tmVjLuAaXzx2IsV1zm5swA3eaqziJmc1NqCBtxmraOJsxgY08hZjFc2cxdiACby9WMUkzl5swETeQuyD1hMbOQOxCt96YiNfPjbAJeFtXPFYBeBjeCtfLjb2uRjeyhWMjX0+hrcqHBuSU6awHavp1RiMx4bkalTYjtX0Rg/GY2OfE4Xt2LTPB+Oxad8WbMcqUsZjQ0WxiopiQ0WxiopiQ0WxiopiQ0WxiopiQ0WxiopiQ02xYCxjGctYxoowlrEjjGXsCGMr/fMaYxlb/CiMzXyUFR0ZUNUwhqrGXFQ1QKSq0Sw7yGVX5rWa44T6Q+SxLXNbzRFYdQ0Xk3/I4VSWov8NMys+EK+qUYNEREREREREREREREREREREREREREREREREq+E/bXKJLpZaCY0AAAAASUVORK5CYII=';

export const FILTER_DISPLAY_TYPE = {
    text: 0,
    number: 5,
    color: 7,
    select: 2,
    multi: 3,
    geo: 4,
    text_pre: 6,
    number_int: 1,
    car_body: 9,
    mot: 10,
    number_gradual: 8,
    measure: 11,
    date: 12,
    float: 13,
    radioCheckBox: 14
};

export const buyPrices = [5, 10, 15, 20, 25, 30, 50, 60, 80, 100, 150, 200, 300, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 11000, 12000, 13000, 14000, 15000, 17500, 20000, 22500, 25000, 27500, 30000, 35000, 40000, 45000, 50000, 60000];
export const motoPrices = [150, 300, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500, 5000, 6000, 7000, 8000, 9000, 10000, 15000, 20000, 25000];
export const rentPrices = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 250, 300, 350, 400, 450, 500, 600, 700, 800, 900, 1000, 1300, 5000, 1700, 2000, 3000, 4000, 5000, 6000, 7000, 8000, 9000];

// LOT END DATE
export const LOT_END_DATE = {
    today: '24h',
    week: '7d',
    month: '30d',
    all: 'All'
};

export const DEFAULT_POWER_UNIT = 'kw';
export const DEFAULT_ENGINE_VOLUME = 'cm3';

// LOT STATUS
export const LOT_STATUS = {
    publish: 'publish',
    close: 'close'
};

// DEBOUNCE TIMES (in ms)
export const DEBOUNCE = {
    SEARCH: 300
};

// FORMS
export const FORMS_AVAILABLE = {
    SIGN_UP: 'SIGN_UP',
    SIGN_IN: 'SIGN_IN',
    PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
    PASSWORD_RECOVERY_DATA: 'PASSWORD_RECOVERY_DATA',
    PAYMENT: 'PAYMENT',
    FILTER_FORM: 'FILTER_FORM',
    USER_NAME_FORM: 'USER_NAME_FORM',
    COMPANY_DATA: 'COMPANY_DATA',
    EDIT_AD: 'EDIT_AD',
    ADD_AD_2STEP_FORM: 'ADD_AD_2STEP_FORM',
    ADD_AD_3STEP_FORM: 'ADD_AD_3STEP_FORM',
    FEED_LIST: 'FEED_LIST',
    XML: 'XML',
    OFFER_FORM: 'OFFER_FORM',
    MESSAGING_FORM: 'MESSAGING_FORM',
    REPORTS: 'REPORTS',
    CHANGE_MAIL: 'CHANGE_MAIL',
    PROFILE_PICTURE: 'PROFILE_PICTURE',
    SOCIAL_ACCOUNTS: 'SOCIAL_ACCOUNTS',
    TEAM: 'TEAM',
    USER_CONTACT_EMAIL: 'USER_CONTACT_EMAIL',
    USER_CONTACT_PHONE: 'USER_CONTACT_PHONE',
    USER_PASSWORD: 'USER_PASSWORD',
    USER_SOCIAL_CONTACTS: 'USER_SOCIAL_CONTACTS'
};

export const SERIALIZER = {
    ADD_AD: 'ADD_AD',
    EDIT_AD: 'EDIT_AD',
    USER_CREDENTIALS: 'USER_CREDENTIALS',
    LOGIN_CREDENTIALS: 'LOGIN_CREDENTIALS',
    FEEDS: 'FEEDS',
    FILTER_FORM: 'FILTER_FORM',
    REPORTS: 'REPORTS',
    FILTER_SUBSCRIPTIONS: 'FILTER_SUBSCRIPTIONS'
};

export const DYNAMIC_BE_FORMS = {
    package: 'package',
    usercontact: 'usercontact',
    filtersubscription: 'filtersubscription',
    report: 'report'
};

export const COSTS = {
    topUp: 1.00,
    oneEuroCreditsCount: 100,
    userContactsFee: 1.00,
    discountBadgePrice: 20,
    highlightPrice: 15,
    allBoostPrice: 55,
    xmlTikTokPrice: 35
};

export const EXTERNAL_MODALS = [MODALS.recovery, MODALS.signup, MODALS.signin];

export const UI = {
    RIGHT_MENU: 'UI_RIGHT_MENU',
    FILTER_BLOCK: 'UI_FILTER_BLOCK',
    FILTER_BLOCK_MOBILE: 'UI_FILTER_BLOCK_MOBILE',
    SORT_BLOCK: 'UI_SORT_BLOCK'
};

// MESSAGING PARTICIPANT TYPES
export const PARTICIPANT_TYPE = {
    company: 2,
    personal: 1,
    admin: 0
};

export const PARTICIPANT_TYPE_STRINGS = {
    [PARTICIPANT_TYPE.personal]: 'personal',
    [PARTICIPANT_TYPE.company]: 'company'
};

export const LOT_EDITABILITY = {
    editable: 1,
    not_editable: 2,
    xml_not_editable: 3
};

export const LOT_MENU_ITEMS = {
    correspondence: 'correspondence',
    bargain: 'bargain',
    share: 'share',
    edit: 'edit',
    close: 'close',
    delete: 'delete',
    remove: 'remove',
    publish: 'publish'
};

export const ACTION_TYPE = {
    link: 'link',
    function: 'function'
};

export const PUBLISH_AS_TYPE = {
    me: 'personal',
    company: 'company',
    mapped: 0,
    skip: 1
};

export const DISCOUNT_FILTER_VALUE = {
    no: 0,
    yes: 1
};

export const PRICE_TYPES = {
    per_sq_m: 15
};
export const DEFAULT_PRICE_TYPE = 1;

export const imgSizes = {
    avatarSmall: '128x128',
    avatarBig: '256x256',
    company_logo_small: '256x256',
    company_logo_big: '512x512', // Currently not generated and not used anywhere
    video: '1280x720',
    thumb: '200x0',
    thumbnail: 'thumb',
    aspect43: '43',
    aspect34: '34',
    aspect32: '32',
    med: '0x500',
    hd: '0x1080',
    qhd: '0x1440',
    '4k': '0x2160',
    original: 'original',
    preview: 'preview'
};

export const videoSizes = {
    thumb: '640x360',
    large: '1280x720'
};

export const fileTypes = {
    image: 1,
    video: 2
};

export const paymentStatus = {
    pending: 'pending',
    paid: 'paid',
    canceled: 'canceled',
    refused: 'refused'
};


export const searchPattern = /[\da-z\u0430-\u044f \u0113\u016b\u0123\u012b\u0101\u0161\u0137\u013c\u017e\u010d\u0146/-]+/ig; // alphanumeric and cyrillic string values

// 20, 22, 23, 121, 123, 125, 143

export const UI_MESSAGE_TYPES = {
    generalerror: 'GENERAL_ERROR',
    generalsuccess: 'GENERAL_SUCCESS'
};

export const TOKENS_REGEX = '^[a-zA-Z0-9]{86}$';

export const NUMBER_REGEX = /^\d{1,9}([,.]\d{1,2})?$/g;
export const MEASURE_NUMBER_REGEX = /^\d\d{0,4}([,.]\d{0,3})?$/;
export const MEASURE_CARS_NUMBER_REGEX = /^\d\d{0,2}([,.]\d{0,3})?$/;

export const RESPONSE_CODES_API = {
    IS_XML_AUTO_PUBLISH_NOT_ALLOWED: 'IS_XML_AUTO_PUBLISH_NOT_ALLOWED',
    USER_HAS_NO_PACKAGES_AVAILABLE: 'USER_HAS_NO_PACKAGES_AVAILABLE',
    NOT_ENOUGH_CREDITS_TOLL_CATEGORY: 'NOT_ENOUGH_CREDITS_TOLL_CATEGORY',
    USER_FORBIDDEN: 'USER_FORBIDDEN',
    FORBIDDEN: 'FORBIDDEN',
    PAYMENT_REQUIRED: 'PAYMENT_REQUIRED'
};

export const HINTS_TYPE = {
    [PP_TYPE.bookmarks]: 'bookmarks',
    [PP_TYPE.list]: 'category',
    [PP_TYPE.my]: 'myItems',
    [PP_TYPE.filters]: 'filters',
    [PP_TYPE.search]: 'search',
    [PP_TYPE.myCompany]: 'myCompany',
    [PP_TYPE.publicCompany]: 'publicCompany',
    [PP_TYPE.filtersCategory]: 'filters'
};


export const LOT_ACTION_TYPE = {
    sell: { id: 1 },
    buy: { id: 2 },
    change: { id: 3 },
    hire: { id: 4 },
    rent: { id: 5 },
    gift: { id: 6 },
    search: { id: 7 },
    offer: { id: 8 },
    found: { id: 9 },
    lost: { id: 10 },
    lookingForHome: { id: 11 },
    charity: { id: 13 }
};

export const CREATE_VIEWS = {
    steps: 'steps',
    detailed: 'detailed'
};

export const cacheableRoutes = [
    '/about/user-data-policy',
    '/about/info/page',
    '/about/info/rules',
    '/contacts',
    '/cooperative'
];


export const API_ERROR_CODES = {
    403: 403
};

export const APPLICATION_SERVER_KEY = 'BNnc1hHlaIwVFAFenxcuEl-75IjDwE-DIJCCDfydl-Le2dFeEGEDRwL1Q9fQEhd_Pi1ylhinSCwgX1BgsuhywnE';

export const SOCIAL_CONTACT_TYPE = {
    facebook: 100,
    instagram: 101
};

export const FACEBOOK_PATH = 'https://www.facebook.com/';
export const INSTAGRAM_PATH = 'https://www.instagram.com/';
export const TELEGRAM_PATH = 'https://telegram.org/';
export const TELEGRAM_BOT = 'https://t.me/pp_smartbot';
export const LOT_VISIBILITIES = {
    publish: 2,
    close: 4,
    deleted: 5,
    blocked: 6,
    draft: 1
};

export const CATEGORY_DEEP_IN_SEARCH = 1;
export const CATEGORY_DEEP_IN_SEARCH_WHEN_CATEGORY_SELECTED = 3;
export const CATEGORY_COUNT_IN_COLLAPSE = 5;

export const ADULT_CONTENT_SLUG = {
    lv: ['preces-pieaugusajiem-18', 'davanas-pieaugusajiem-18'],
    ru: ['tovary-dlia-vzroslykh-18', 'podarki-dlia-vzroslykh-18']
};

export const SUGGESTED_CATEGORY_COUNT_IN_COLLAPSE = 1;
export const MINIMAL_RATING = 3;

export const EXCLUDED_COMPANY_SELLER_LIST = {
    175: 'CARBUY'
};

export const EXCLUDED_CATEGORIES_IN_CREATE = [CATEGORIES.christmas];

export const PREF_TITLE = {
    user_public_name: {
        title: i18n => (i18n.__('Show surname public')),
        backendKey: 'user_public_name'
    },
    user_public_email: {
        title: i18n => (i18n.__('Show email publicly')),
        backendKey: 'user_public_email'
    }
};

export const INITIAL_ACTUAL_PAGE = 1;
export const INITIAL_SECOND_PAGE = 2;
export const OBSERVER_PAGE_COUNT = 2;
// how many pages are loaded with observer

export const SKELETON_LOT_COUNT_HOME = 4;
export const CHILD_CATEGORIES_COUNT_HOME = 5;
export const INBOX_LOGIN_PATH = 'https://login.inbox.lv';
export const CANCELLED_BY_USER = 'cancelled_by_user';
export const INVALID_REQUEST = 'invalid_request';
export const VISIT_LOT_COUNT_SHOW_SUBSCRIBE = 10;

export const DEFAULT_ONE_TAP_HEIGHT = [330, 360];
export const USER_CARD_HEIGHT_FOR_ONE_TAP = 170;
export const FILTER_FIELD_MODEL_ID = 263;
export const FILTER_FIELD_BRANDS_ID = 535;
export const FILTER_FIELD_NEW_MODEL_ID = 30;
export const LANDING_DEFAULT_ENGINE_VOLUME_L = { 22: { type: 2363 } };
export const CREATE_CAR_INITIAL_FILTERS = { 114: 1896, 463: 9039 };
export const WINTER_BEATER_FILTER_ID = 463;
export const MOBILE_PHONE_TYPE_FIELD = 265;
export const MOBILE_PHONE_MODEL_FIELD = 263;
export const WINTER_BEATER_FILTER = 9037;
export const INCLUDED_FILTER_VALUES = {
    9037: 'winter_beater',
    1895: 'with_defects'
};
export const FILTER_NEW_ID = 575;
export const FILTER_USED_ID = 576;

export const FEED_UPLOAD_TYPE = { byHand: 0, link: 1, mozello: 3 };
export const GTM_EVENTS = {
    headerSearchButton: 'headerSearch-SearchButton',
    headerSearchSeeAll: 'headerSearch-SeeAll',
    headerSearchSeeAllCategory: 'headerSearch-SeeAllCategory',
    headerSearchClickAd: 'headerSearch-ClickAd',
    headerSearchClickCategory: 'headerSearch-ClickCategory',
    headerSearchEnter: 'headerSearch-Enter'
};

export const SLOW_NETWORK_SPEED_IN_KBPS = 100;
export const STATIC_IMAGE_SIZE = 1100; // bytes
export const ROTATE_CLASS = 'rotated';
export const PRICE_STEP = 4;
export const STATUS = {
    OK: 200
};

export const filterId = {
    interior: 4,
    safety: 9,
    transmission: 14,
    fuel: 15,
    color: 18,
    carType: 19,
    calendar: 20,
    engineVolume: 22,
    distance: 23,
    mot: 24,
    enginePower: 25,
    defect: 114,
    numberOfRooms: 121,
    areaM2: 123,
    houseType: 129,
    houseSeries: 127,
    floor: 125,
    numberOfFloors: 139,
    landArea: 143,
    clothesSize: 443,
    liftingPower: 81,
    volumeM3: 78,
    speedIndex: 71
};
export const FILTER_FOR_BUILDING_BLOCK = { [filterId.floor]: true, [filterId.numberOfRooms]: true, [filterId.areaM2]: true, [filterId.numberOfFloors]: false };

export const MINIMAL_CURRENT_VIEWS_PER = {
    currentView: 1,
    period30min: 5,
    period24h: 10
};

export const UNSEEN_LOT_COUNT = 1;
export const MINIMAL_ACTION_COUNT = 2;
export const SHOW_CATEGORY_SELECT_LEVEL = 1;
export const MAX_DESCRIPTION_LENGTH = 1100;
export const MAX_DESCRIPTION_PARAGRAPHS_LENGTH = 12;
export const SHORT_META_DESCRIPTION_LENGTH = 100;
export const MAX_META_DESCRIPTION_LENGTH = 135;
export const MAX_VIDEO_SIZE_BYTES = 1000000000;
export const MAX_IMAGE_SIZE_BYTES = 50000000;

export const KEYBOARD_CODES = {
    enter: 13,
    capslock: 20,
    escape: 27,
    arrowUp: 38,
    arrowDown: 40
};

export const USER_SETTINGS = {
    user_public_name: 'user_public_name',
    user_public_email: 'user_public_email',
    company_classifieds_user_info: 'company_classifieds_user_info',
    user_receive_digest_similar: 'user_receive_digest_similar',
    user_receive_price_change: 'user_receive_price_change',
    user_review_lots: 'user_review_lots',
    lots_view_type: 'lots_view_type',
    contact_display_telegram: 'contact_display_telegram',
    contact_display_whatsapp: 'contact_display_whatsapp'
};

export const SINGLE_PRICE = 1;

export const EXCLUDED_CATEGORIES_MASONRY_TEXT = [CATEGORIES.flats, CATEGORIES.homes, CATEGORIES.cars, CATEGORIES.moto];


export const SUPPORT_PHONE = '+371 60003635';
export const DATE_FORMAT_API = 'YYYY-MM-DD';
export const DATE_FORMAT_FRONT = 'DD-MM-YYYY';

export const LANDING_CATEGORY_LOTS_COUNT = {
    xxl: 5,
    xl: 4,
    lg: 3,
    md: 2,
    sm: 2,
    xs: 2
};

export const FULLWIDTH_CATEGORY_LOTS_COUNT = {
    xxl: 6,
    xl: 5,
    lg: 4,
    md: 3,
    sm: 2,
    xs: 2
};

export const LANDING_CATEGORY_MENU_COUNT = {
    xxl: 4,
    xl: 3,
    lg: 2,
    md: 0,
    sm: 0,
    xs: 0
};

export const BRAKEPOINT_XXL = 1500;
export const BRAKEPOINT_XL = 1280;
export const BRAKEPOINT_LG = 1024;
export const BRAKEPOINT_MD = 768;
export const BRAKEPOINT_SM = 480;

export const CLASSIFIED_WIDTH = 220;
export const CATEGORY_COUNT_FIRST_FETCH = 4;
export const HOME_ACTIVE_CHILD_COUNT = 6;
export const CATEGORY_DISABLE_COUNT = 6;

export const CONTACT_STATUS = {
    not_verified: 0,
    verified: 1,
    verified_non_primary: -1
};

export const EXCLUDED_LANDING_FOR_CATEGORY = [CATEGORIES.realEstateSaunas, CATEGORIES.realEstateModularHomes, CATEGORIES.realEstateGarage, CATEGORIES.realEstateForest];
export const MULTI_LEVEL_CATEGORIES = { 148: 'BMW', 1998: 'Volkswagen', 1095: 'Mercedes', 131: 'Bentley' };
export const OTHER_CATEGORY_NAME = ['Cits', 'Другое'];
export const regexValidationPatterns = {
    email: '^((?=.{4,255}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9]{2,6})'
};
export const LOT_ACTIONS_FOR_DISCOUNT = [LOT_ACTION_TYPE.sell.id, LOT_ACTION_TYPE.rent.id];
export const DEFAULT_DISCOUNT = 15;
export const MIN_DISCOUNT = 15;
export const MAX_DISCOUNT = 90;
export const DEFAULT_DISCOUNT_PERIOD = 14; // days
export const DISCOUNT_PERIOD = [7, 14, 21];

export const GEMIUS_IDENTIFIER = 'zZ2aoScuowDST4XCDmL9tXaInIPBfqPTIXGMnM5UCOr.K7';
export const CARD_YEARS = 8;
export const CARD_MONTHS = 12;
export const LOT_COUNT_IN_TWO_ROWS = {
    desktop: 10,
    mobile: 2
};

export const LOST_XML_LOTS_ACCURACY = 2;
export const SHOW_LEAF_CATEGORY_COUNT = 5;
export const SHOW_MAPPING_CATEGORY_COUNT = 1;
export const DISTANCE_THRESHOLD = 0.001;
export const COLOR_THEME = { light: 'light', dark: 'dark' };
export const LIMIT_FOR_COMPARE_LIST = 25;

export const DEFAULT_CLASSIFIED_PERIOD = 14; // days
export const TIK_TOK_CARS = 'https://www.tiktok.com/@pp.lv_auto';
export const TIK_TOK_FLATS = 'https://www.tiktok.com/@pp.lv_flats';
export const LOT_STATUSES_FOR_MSG_ARCHIVE = [LOT_VISIBILITIES.close, LOT_VISIBILITIES.deleted, LOT_VISIBILITIES.blocked];
export const LOT_STATUSES_FOR_MSG_ACTIVE = [LOT_VISIBILITIES.publish, LOT_VISIBILITIES.draft];
export const DELIVERY_FILTER = 113;

export const AMOUNT_OPTIONS = [1.00, 2.00, 5.00, 7.00, 10.00];
export const EXCLUDE_COMPANIES_FROM_IMAGE_SHUFFLE = [1609];

export const CATEGORY_DISCOUNT_OBJECT = {
    id: 'discount',
    name: { en: 'Discount', lv: 'Preces ar atlaidi', ru: 'Товары по скидке' },
    slug: { en: 'discount', lv: 'discount', ru: 'discount' }
};
