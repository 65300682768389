import React, { useRef, useState, useEffect, memo } from 'react';
import size from 'lodash/size';
import forEach from 'lodash/forEach';
import classNames from 'classnames';
import Storage from 'HOC/storage';
import { IMAGE_PANORAMA_TYPE, STORAGE } from 'CONFIG';
import { stringExistsIn } from 'MODULES/validations';
import { fileTypes } from 'CONST';
import SeoConfig, { ATTRIBUTE } from 'COMPONENTS/seo_config';
import { isMobile } from 'react-device-detect';
import { buildImgUrl } from 'MODULES/buildImgUrl';
import { ratioImg } from 'MODULES/ratioImg';
import { getParent } from 'MODULES/getParent';
import { checkNested } from 'MODULES/checkNested';
import { isCategoryPicturePriority } from 'MODULES/isCategoryPicturePriority';
import { NOT_FOUND_IMAGE } from 'CONFIG/NOT_FOUND_IMAGE';
import { CATEGORIES } from 'MODULES/CATEGORIES';
import { PP_TYPE } from 'MODULES/PP_TYPE';
import { CLASSIFIEDS_VIEW } from 'MODULES/CLASSIFIEDS_VIEW';
import { isEndDateApproaching } from 'MODULES/handlers';

const ImageThumbGallery = ({ pp, list, ppType, i18n, isClosed, swipe }) => {
    const adultContentCheck = Storage.get(STORAGE.adultContent, true, true);
    const blurAdultContent = checkNested(pp.category, 'parent.id') === CATEGORIES.adultContent && !adultContentCheck && ppType === (PP_TYPE.publicCompany || PP_TYPE.user);
    const [images, setImages] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [hasPanorama, setHasPanorama] = useState(false);
    const [hasVideo, setHasVideo] = useState(false);
    const isPicturePriority = isCategoryPicturePriority(pp.category);
    const isClothingCategory = pp.category.viewType === CLASSIFIEDS_VIEW.clothes;
    const parentId = getParent(checkNested(pp, 'category'), true);
    const isRealEstateCategory = checkNested(parentId, [0, 'id']) === CATEGORIES.realEstate;
    const elementRef = useRef(null);
    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);

    useEffect(() => {
        let lotImages = [];
        forEach(checkNested(pp, 'files', []), (item) => {
            if (item.file.outputType === 'video') {
                setHasVideo(true);
                lotImages = [...lotImages, {
                    original: item.file.converted ? `${buildImgUrl(item.file, 'preview')}` : NOT_FOUND_IMAGE,
                    thumbnail: item.file.converted ? `${buildImgUrl(item.file, 'preview')}` : NOT_FOUND_IMAGE,
                    type: 2
                }];
            } else {
                const original = checkNested(item, 'file') ? `${buildImgUrl(item.file, 'med', '')}` : [];
                if (item.type === IMAGE_PANORAMA_TYPE) setHasPanorama(true);
                let thumbnailClass;
                let originalClass;
                if (item.file.rotation && stringExistsIn('original.', original)) {
                    originalClass = `rotate-${item.file.rotation * 90}`;
                    thumbnailClass = originalClass;
                }
                lotImages = [...lotImages, {
                    thumbnailClass,
                    originalClass,
                    original:  item.file ? `${buildImgUrl(item.file, 'thumbnail', '')}` : NOT_FOUND_IMAGE,
                    thumbnail: item.file ? `${buildImgUrl(item.file, 'thumbnail', '')}` : NOT_FOUND_IMAGE,
                    aspect43: item.file ? `${buildImgUrl(item.file, 'aspect43', '')}` : NOT_FOUND_IMAGE,
                    aspect34: item.file ? `${buildImgUrl(item.file, 'aspect34', '')}` : NOT_FOUND_IMAGE,
                    aspect32: item.file ? `${buildImgUrl(item.file, 'aspect32', '')}` : NOT_FOUND_IMAGE,
                    ratio: item.file ? `${ratioImg(item.file, 'thumbnail', '')}` : NOT_FOUND_IMAGE,
                    isConverted: true
                }];
            }
        });
        setImages(lotImages);
    }, [pp.id]);
    const [isVisible, setIsVisible] = useState(false);
    useEffect(() => {
        const checkVisibility = () => {
            if (elementRef.current) {
                const rect = elementRef.current.getBoundingClientRect();
                const windowHeight = window.innerHeight || document.documentElement.clientHeight;
                const windowWidth = window.innerWidth || document.documentElement.clientWidth;

                const isVisible = (
                    rect.top >= 0
                    && rect.left >= 0
                    && rect.bottom <= windowHeight
                    && rect.right <= windowWidth
                );

                setIsVisible(isVisible);
            }
        };

        setTimeout(checkVisibility, 0);
    }, []);
    const ratio = checkNested(images, [currentIndex, 'ratio']);
    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };
    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };
    const handleTouchEnd = () => {
        if (touchStartX && touchEndX) {
            const diff = touchEndX - touchStartX;
            if (Math.abs(diff) > 50) {
                if (diff > 0) {
                    decrementIndex();
                } else {
                    incrementIndex();
                }
            }
        }
        setTouchStartX(null);
        setTouchEndX(null);
    };
    useEffect(() => {
        const element = elementRef.current;
        if (element && !swipe) {
            element.addEventListener('touchstart', handleTouchStart);
            element.addEventListener('touchmove', handleTouchMove);
            element.addEventListener('touchend', handleTouchEnd);

            return () => {
                element.removeEventListener('touchstart', handleTouchStart);
                element.removeEventListener('touchmove', handleTouchMove);
                element.removeEventListener('touchend', handleTouchEnd);
            };
        }
    }, [handleTouchStart, handleTouchMove, handleTouchEnd]);

    const decrementIndex = () => {
        setCurrentIndex(currentIndex - 1 < 0 ? size(images) - 1 : currentIndex - 1);
    };
    const incrementIndex = () => {
        setCurrentIndex(currentIndex + 2 > size(images) ? 0 : currentIndex + 1);
    };
    const getAspectKey = (isPicturePriority, isClothingCategory, isRealEstateCategory) => {
        if (isPicturePriority && isClothingCategory) {
            return 'aspect34';
        }
        return isRealEstateCategory ? 'aspect32' : 'aspect43';
    };
    const aspectKey = getAspectKey(isPicturePriority, isClothingCategory, isRealEstateCategory);
    return (
        <div
            className={classNames('thumbnails-wrapper overflow-hidden', { 'list-view': list, 'list-image': list, 'list-image-wrap': list })}
            onClick={(e) => {
                const buttonLeft = document.querySelector(`#classified-${pp.id} .image-gallery-left-nav`);
                const buttonRight = document.querySelector(`#classified-${pp.id} .image-gallery-right-nav`);
                if ((buttonLeft && buttonLeft.contains(e.target)) || (buttonRight && buttonRight.contains(e.target))) {
                    e.preventDefault();
                }
            }}
        >
            {size(images) > 0
                && (
                    <div className="thumbnail-gallery" ref={elementRef}>
                        <div className="d-flex gallery-info">
                            {hasVideo
                                && <span className="gallery-info-badge"><i className="pp-landing pp-video-camera" /></span>}
                            {hasPanorama && (
                                <span className="gallery-info-badge">
                                    <i className="pp-landing pp-gallery-panorama fs-1-5rem" />
                                </span>
                            )}
                        </div>
                        {isClosed && (
                            <span className="alert alert-danger classified-sold text-center">{isEndDateApproaching(new Date(pp.endDate)) ? i18n.__('Expired') : i18n.__('Sold')}</span>
                        )}
                        {size(images) <= 1 || (!swipe && isMobile) || !isMobile
                            && (
                                <div>
                                    <button
                                        type="button"
                                        className="image-gallery-icon image-gallery-left-nav"
                                        aria-label="Previous Slide"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            decrementIndex();
                                        }}
                                    >
                                        <svg
                                            className="image-gallery-svg image-gallery-left image-gallery-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="6 0 12 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polyline points="15 18 9 12 15 6" />
                                        </svg>
                                    </button>
                                    <button
                                        type="button"
                                        className="image-gallery-icon image-gallery-right-nav"
                                        aria-label="Next Slide"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            incrementIndex();
                                        }}
                                    >
                                        <svg
                                            className="image-gallery-svg image-gallery-right image-gallery-icon"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="6 0 12 24"
                                            fill="none"
                                            stroke="currentColor"
                                            strokeWidth="1"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polyline points="9 18 15 12 9 6" />
                                        </svg>
                                    </button>
                                </div>
                            )}
                        {size(images) > 1 && (
                            <div className="image-gallery-index-thumb-categories">
                                {`${currentIndex + 1} / ${size(images)}`}
                            </div>
                        )}
                        {blurAdultContent && <i className="pp-category pp-zcat_adult adult-icon" />}
                        {(checkNested(images, [currentIndex, 'original'], []).indexOf('original.') === -1 || checkNested(images, [currentIndex, 'type']) !== fileTypes.video) && (
                            <img
                                alt={SeoConfig(pp, ATTRIBUTE.alt)}
                                title={SeoConfig(pp, ATTRIBUTE.imgTitle)}
                                loading={isVisible ? 'eager' : 'lazy'}
                                className={classNames(blurAdultContent ? 'post-images modal-adult-content' : 'post-images', { [checkNested(images, [currentIndex, 'originalClass'])]: checkNested(images, [currentIndex, 'originalClass']), 'bg-size-contain':  ratio < 0.4 || ratio > 2.20 })}
                                src={checkNested(images, [currentIndex, 'isConverted']) ? checkNested(images, [currentIndex, list ? 'aspect43' : aspectKey]) : checkNested(images, [currentIndex, 'original'])}
                            />
                        )}
                        {checkNested(images, [currentIndex, 'original'], []).indexOf('original.') !== -1 && checkNested(images, [currentIndex, 'type']) === fileTypes.video
                            && (
                                <video style={{ marginTop: '-20px', width: '100%' }} controls height="200" poster={checkNested(images, [currentIndex, 'thumbnail'])}>
                                    <source src={checkNested(images, [currentIndex, 'original'])} type="video/webm" />
                                    <source src={checkNested(images, [currentIndex, 'original'])} type="video/mp4" />
                                </video>
                            )}
                    </div>
                )}
        </div>
    );
};

export default memo(ImageThumbGallery);
