/* API URLS */
export const
    API_URLS = {
        // Bookmarks
        FAVORITES: 'favorites',
        FAVORITES_DETAILED: 'favorites/detailed',
        FAVORITES_BY_ID: id => (`favorites/${id}`),

        // Compare
        COMPARE: 'compare',
        COMPARE_DETAILED: 'compare/detailed',
        COMPARE_LOT_BY_ID: id => (`lots/${id}/compare`),

        // Company
        AUTEGO: 'proxy/autego',
        FILTERS_BUILDING_SUGGEST: building => `filter/building/${building}/suggest`,
        COMPANY: 'companies',
        COMPANY_BY_ALIAS: alias => (`companies/alias/${alias}`),
        COMPANY_BY_ID: id => (`companies/${id}`),
        COMPANY_FEEDS: id => (`companies/${id}/feeds`),
        COMPANY_FEEDS_BY_ID: (company, feeds) => (`companies/${company}/feeds/${feeds}`),
        COMPANY_MEMBERS: id => (`companies/${id}/members`),
        COMPANY_MEMBERS_BY_ID: (company, member) => (`companies/${company}/members/${member}`),
        COMPANY_LOTS: 'companies/lots',
        COMPANY_LOTS_BY_ID: id => (`companies/${id}/lots`),
        COMPANY_LOTS_BY_ID_BY_CATEGORY: (id, category) => (`companies/${id}/category/${category}/lots`),
        COMPANY_LOTS_CATEGORIES: 'companies/lots/categories',
        COMPANY_LOTS_CATEGORIES_BY_ID: id => (`companies/${id}/lots/categories`),
        COMPANY_INVITES: id => (`companies/${id}/invites`),
        COMPANY_INVITES_BY_ID: (company, invite) => (`companies/${company}/invites/${invite}`),
        COMPANY_ACCEPT_INVITE: (company, hash) => (`companies/${company}/invites/accept/${hash}`),
        COMPANY_MEMBER: (company, user) => (`companies/${company}/members/${user}`),
        COMPANY_AVATAR: 'companies/avatar',
        GET_DELIVERY_POINTS: 'delivery/pickup-points',

        // Categories
        CATEGORIES: 'categories',
        HOME_DISABLED_CATEGORIES: token => (`watch_history/homepage/${token}`),
        HOME_CHANGE_CATEGORY: (token, category) => (`watch_history/homepage/${token}/${category}`),
        CATEGORIES_FULL_TREE: 'categories/tree',
        CATEGORIES_BY_ID: id => (`categories/${id}`),
        CATEGORIES_MAP: id => (`categories/${id}/map`),
        CATEGORIES_TREE: category => (`categories/${category}/tree`),
        SUBCATEGORIES: category => (`categories/${category}/subcategories`),
        REAL_SUBCATEGORIES: category => (`categories/${category}/real-subcategories`),
        CATEGORY_LOTS: id => (`categories/${id}/lots`),
        RECENTTLY_CREATED: () => ('lot_feed/detect'),
        DISCOUNT_LOTS: () => ('lots/discount'),
        CATEGORY_FILTERS: id => (`categories/${id}/filters`),
        CHANGE_CATEGORY: lot => (`lots/${lot}/category`),
        CATEGORY_FILTERS_COMPANY: (id, companyId) => (`categories/${id}/filters/company/${companyId}`),
        SLUG_CATEGORIES: slug => (`slugs/categories/${slug}`),
        SLUG_SUBCATEGORIES: slug => (`slugs/categories/${slug}/subcategories`),
        ADD_CATEGORY: parent => (`categories/${parent}/missing`),
        CATEGORY_HINTS: category => (`categories/${category}/hints`),

        // Home
        HOME_CATEGORIES_PRERENDER: 'categories/home',
        HOME_CATEGORIES: watch => `categories/home/${watch}`,

        // Lots
        DIGEST: identifier => `watch_history/suggested/${identifier}`,
        RECENLTY_ADDED: (category, region) => (region ? (`lots/recent/${category}/region/${region}`) : (`lots/recent/${category}`)),
        RECENLTY_ADDED_ALL: 'lots/recent',
        MORE_TO_LOVE: id => `lots/more/${id}`,
        christmas: 'lots/christmas',
        LOTS: 'lots',
        LOTS_EXPIRED_PLAN: plan => (`lots/expired/${plan}`),
        LOTS_BY_ID: id => (`lots/${id}`),
        CLONE_CLASSIFIED: id => (`lots/${id}/duplicate`),
        LOTS_BY_ID_DETAILED: id => (`lots/${id}/detailed`),
        LOTS_BY_ID_SIMILAR: (id, identifier) => (`lots/${id}/similar/${identifier}`),
        LOTS_FILES: id => (`lots/${id}/files`),
        LOTS_FILES_BY_ID: (lot, file) => (`lots/${lot}/files/${file}`),
        LOTS_STATISTIC_BY_ID: lot => (`lots/${lot}/statistics`),
        LOTS_CATEGORIES: 'lots/categories',
        LOTS_OFFERS: id => (`lots/${id}/offers`),
        LOTS_STATUS: 'lots',
        OFFERS: 'offers',
        LOT_QR_CODE: classified => (`lots/${classified}/qr`),
        RENEW_CLASSIFIED: (classified, hash) => (`lots/${classified}/top/${hash}`),
        LOT_POSITION: id => (`lots/${id}/position`),
        LOT_BOOST_TO_TOP: id => (`lots/${id}/top`),
        LOT_BOOST_TO_TIK_TOP: id => (`lots/${id}/tiktok`),
        LOT_BOOST_HIGHLIGHT: id => (`lots/${id}/highlight`),
        LOT_SUGGEST_EMAIL: id => (`lots/${id}/suggest`),
        PASSWORD_STRENGTH: 'password_strength/',
        // Files
        FILES: 'files',
        FILES_QR: (lot, hash) => `lots/${lot}/qr/${hash}/upload`,
        FILES_QR_CLASSIFIED: (lot, hash) => (`lots/${lot}/qr/${hash}/link`),
        THUMBS: 'thumbnails',
        THUMBS_QR: (lot, hash) => (`lots/${lot}/qr/${hash}/upload/thumbnail`),
        FILES_BY_ID: id => (`files/${id}`),
        THUMBNAIL_BY_ID: id => (`thumbnails/${id}`),
        LOT_AMENITIES: id => (`lots/${id}/amenities`),
        CLOSED_LOT_CONTACTS: ad => (`lots/${ad}/peek`),
        TOLL_CATEGORY_PRICE: ad => (`lots/${ad}/publish`),
        LOT_FAVORITES_COUNT: id => (`lots/${id}/favorites`),
        LOT_THREAD_COUNT: id => (`threads/${id}/count`),

        // Subscriptions
        LOT_SUBSCRIPTIONS: 'lot_subscription',
        FILTER_SUBSCRIPTIONS: 'filter_subscriptions',
        FILTER_SUBSCRIPTIONS_SAVE: category => `categories/${category}/filter_subscriptions`,
        FILTER_COUNT: 'filter_subscriptions/digest',
        FILTER_SUBSCRIPTIONS_BY_ID: id => `filter_subscriptions/${id}`,
        FILTER_SUBSCRIPTIONS_STATUS: id => `filter_subscriptions/${id}/status`,
        FILTER_SUBSCRIPTIONS_DIGEST: hash => `filter_subscriptions/${hash}/digest`,
        FILTER_SUBSCRIPTIONS_LOTS: hash => `filter_subscriptions/${hash}/lots`,
        FILTER_SUBSCRIPTIONS_LOTS_UNSEEN: hash => `filter_subscriptions/${hash}/unseen`,
        FILTER_SUBSCRIPTIONS_LOTS_SEEN: (hash, id) => `filter_subscriptions/${hash}/lots/${id}`,
        PATCH_FILTERED_CLASSIFIED: id => `lots/${id}/filter_subscription`,

        // Subscribers
        SUBSCRIBERS: 'subscribers',
        ADD_SUBSCRIBER: watch => `subscribers/${watch}`,
        LOTS_LIST: list => `lots/${list}/list`,

        // Messages
        THREADS: 'threads',
        NOTIFICATIONS: 'notifications',
        NOTIFICATIONS_READ: id => (`notifications/${id}`),
        MESSAGES_TOTAL: 'messages/total',
        THREADS_COMPANY: 'company/threads',
        THREADS_BY_ID: id => (`threads/${id}`),
        ITEMS_BY_HASH: hash => (`items/${hash}`), // 'lotId' by hash
        MESSAGES_BY_HASH: hash => (`messages/${hash}`), // messages by hash
        NEW_MESSAGES: 'messages/new',
        // Wallet
        WALLET_DATA: 'user/packages/wallet',
        WALLET_LOG_DATA: 'packages/personal/logs',
        // Packages
        PACKAGES_TEMPLATES: 'packages/templates',
        PACKAGES_PERSONAL_INVOICES: 'packages/personal/invoices',
        PACKAGES_COMPANY_INVOICES: 'packages/company/invoices',
        PACKAGES_BY_TEMPLATE: id => (`packages/templates/${id}`),
        PACKAGES_BY_ID: id => (`packages/${id}/payments`),
        // PAYMENT
        PAYMENT_STATUS: id => (`payment?transaction_uuid=${id}`),
        // Hints
        HINTS: listType => `hints/${listType}`,
        // Feeds specification
        FEEDS_SPECIFICATION: category => `feeds/specification/example/${category}`,
        // Watch history
        WATCH_HISTORY_IDENTIFIER: 'watch_history/identifier',
        WATCH_HISTORY_LINK: watchCookie => (`watch_history/link/${watchCookie}`),
        WATCH_HISTORY_LOTS: id => (`watch_history/lots/${id}`),
        WATCH_HISTORY_CATEGORIES: id => (`watch_history/categories/${id}`),

        LOT_WEBSITE_CLICK: id => (`lots/${id}/click`),

        // Search
        SEARCH: 'search',
        RECENT_SEARCHES: 'search/suggestion',
        SEARCH_LOTS: 'search/lots',
        SEARCH_LOTS_FILTERS_USED: 'search/lots/filters/used',
        SEARCH_BY_COORDINATES: 'search/geo/locations',
        SEARCH_GEO: query => (`geo/buildings/autocomplete?query=${query}`),
        SEARCH_CATEGORY: category => (`search/categories/autocomplete?query=${category}`),
        // User
        PARSER_CREATE: 'parser/create',
        IMPERSONATE: 'impersonate',
        LOGOUT: 'logout',
        LOGIN: 'login',
        LOGIN_BY_PROVIDER: provider => (`login/${provider}`),
        USER: 'user',
        FEEDS: 'feeds',
        FEED_BY_ID: id => `feeds/${id}`,
        FEED_DISABLE_BY_ID: id => `feeds/${id}/disable`,
        FEED_ENABLE_BY_ID: id => `feeds/${id}/enable`,
        FEEDS_EXAMPLE: id => `feeds/specification/example/download/${id}`,
        FEED_LOGS_BY_ID: id => `feeds/result/log/${id}`,
        FEED_CATEGORIES_BY_ID: id => `feeds/result/category/${id}`,
        FEED_CATEGORY_MAPPING: (feed, categoryImport) => `feeds/${feed}/map/${categoryImport}`,
        USER_BY_ID: id => (`user/${id}`),
        PREFERENCES: 'user/prefs',
        PREFERENCE: prefKind => `user/prefs/prefkind/${prefKind}`,
        PREFER_USER_CONTACT: userContact => `user/contacts/${userContact}/prefer`,
        USER_CONTACTS: 'user/contacts',
        USER_LOTS: user => (`user/${user}/lots`),
        USER_CONTACTS_BY_ID: id => (`user/contacts/${id}`),
        USER_PACKAGES: 'user/packages',
        USER_PACKAGES_EXPIRED: 'user/packages/expired',
        USER_PASSWORD: 'user/password',
        USER_AVATAR: 'user/avatar',
        USER_RECOVERY: hashOrEmail => (`user/recovery/${hashOrEmail}`),
        USER_RECOVERY_PASSWORD_PHONE_CODE: code => (`user/recovery/code/${code}`),
        USER_LOTS_CATEGORIES: user => (`user/${user}/lots/categories`),
        USER_LOTS_BY_ID_BY_CATEGORY: (user, category) => (`user/${user}/category/${category}/lots`),
        USER_SOCIALS: 'user/social',
        USER_REFERRAL: 'referral',
        USER_REFERRAL_HASH: (user, hash) => (`referral/${user}/${hash}`),
        COMPANY_SOCIALS: 'companies/social',
        SOCIAL_DELETE_BY_TYPE: (type, social) => (`${type}/social/${social}`),

        // FORMS
        FORMS: 'config/form',
        FORMS_ARGS: 'config/form/args',
        FORM_BY_NAME: id => (`config/form/${id}`),

        // Reports
        SEND_REPORT: type => (`reports/${type}`),
        REASON: reason => (`reason/${reason}`),
        // Geo
        REGION: region => (`geo/regions/${region}`),
        STREET: street => (`geo/regions/${street}/streets`),
        REGIONS: 'geo/regions',
        REGIONS_POPULAR: 'geo/regions/popular',
        CONTACT: 'contact',
        REGIONS_BY_ID: parentRegion => (`geo/regions/${parentRegion}/subregions`),
        REGIONS_BY_SLUG: slug => (`slugs/geo/regions/${slug}/subcategories`),
        LOTS_BY_BUILDING_ID: id => (`geo/buildings/${id}/lots`),

        // Static
        STATIC_PAGE: page => (`static/${page}`),
        STATIC_FILE: file => (`static/file/${file}`),

        // Partners
        PARTNERS: 'partners',
        PARTNERS_BY_CATEGORY: category => (`categories/${category}/partners`),

        // Review
        LOT_REVIEWS: lotId => (`lots/${lotId}/reviews`),
        CHECK_LOT_MY_REVIEW: lotId => (`lots/${lotId}/reviews/my`),
        SEND_LOT_REVIEW: lotId => (`lots/${lotId}/reviews`),
        ADD_LOT_DISCOUNT: lotId => (`lots/${lotId}/discount`),
        ADD_FILES_REVIEW: reviewId => (`reviews/${reviewId}/files`),
        PUBLISH_REVIEW: reviewId => (`reviews/${reviewId}`),

        // Inbank
        INBANK_EXTERNAL_PARTNER: 'api/inbank_partner',
        INBANK_APPLICATIONS: 'api/applications',
        INBANK_APPLICATIONS_ATTACHMENTS: uuid => (`api/applications/${uuid}/attachments`),
        INBANK_APPLICATIONS_ACCEPT: uuid => (`api/applications/${uuid}/accept`),
        INBANK_CONTRACTS_SIGNINGS: uuid => (`api/contracts/${uuid}/signings`),
        INBANK_CONTRACTS: uuid => (`api/contracts/${uuid}`)
    };
