import { createSelector } from 'reselect';
import { removeLastSlash } from 'MODULES/api';
import { classifiedsPagePath } from 'MODULES/reselect/selectors/classified';
import { matchPath } from 'react-router-dom';
import size from 'lodash/size';
import keyBy from 'lodash/keyBy';
import get from 'lodash/get';
import map from 'lodash/map';
import { locationPathnamePpTypeState } from 'MODULES/reselect/selectors/global';
import { countState } from 'MODULES/reselect/state';
import { checkNested } from 'MODULES/checkNested';
import { sortCategoriesByHighlight } from 'MODULES/sortCategoriesByHighlight';
import { PP_URLS } from 'CONFIG/PP_URLS';
import { PP_TYPE } from 'MODULES/PP_TYPE';


const emptyObject = {};
const routeSearchCategory = (state, props) => {
    const path = PP_URLS.PP(':id', '**', ':lang');
    let match = matchPath(props.location.pathname, {
        path,
        exact: true,
        strict: false
    });

    if (!match || !size(match)) {
        match = matchPath(props.location.pathname, {
            path: PP_URLS.LANDING(':lang', '**'),
            exact: true,
            strict: false
        });
    }


    if (!match || !size(match)) {
        match = matchPath(props.location.pathname, {
            path: PP_URLS.CATEGORY('**', ':lang'),
            exact: true,
            strict: false
        });
    }


    return match && match.params && match.params[0];
};


export const getCustomSmallClassifiedFullState = createSelector(
    state => state.categories,
    categories => categories.ppsList[PP_TYPE.smallPpsList]
);

export const startFinishSelector = createSelector(
    [(state, props) => props],
    props => ({ start: props.start, finish: props.finish })
);

export const getCustomSmallClassifiedState = createSelector(
    [getCustomSmallClassifiedFullState,
        locationPathnamePpTypeState,
        startFinishSelector],
    (list, pathname) => {
        const original = list[pathname];
        return original;
    }
);


export const getAllCategories = createSelector(
    [state => state.categories],
    categories => checkNested(categories, 'categories')
);

export const getCoordinateClassifieds = createSelector(
    [state => state.categories.ppsByCoordinates],
    pps => pps
);

export const getCategoriesById = createSelector(
    [getAllCategories],
    categories => get(categories, 'byId', {})
);

export const getCategoriesBySlug = createSelector(
    [getAllCategories],
    categories => get(categories, 'bySlug', {})
);

export const getCategoriesCurrentCategory = createSelector(
    [getAllCategories],
    categories => get(categories, 'currentCategory', {})
);

export const getActivePage = createSelector(
    [state => state.categories.activePage, classifiedsPagePath],
    (pages, pagePath) => get(pages, pagePath, null)
);


export const getCurrentCategory = createSelector(
    [
        (state, props) => props.match.params[0],
        getCategoriesById,
        getCategoriesBySlug
    ],
    (slug, byId, bySlug) => byId[bySlug[removeLastSlash(slug)]]
);


export const getCurrentCategoryFromLink = createSelector(
    [
        routeSearchCategory,
        getCategoriesById,
        getCategoriesBySlug
    ],
    (slug, byId, bySlug) => slug && byId[bySlug[removeLastSlash(slug)]]
);

export const categoriesCurrentCategorySelector = createSelector(
    [
        getCurrentCategory,
        (state, props) => props.currentCategory
    ],
    (category, categoryFromProp) => categoryFromProp || category
);

export const categoriesCurrentCategorySelectorId = createSelector(
    [
        getCurrentCategory
    ],
    category => checkNested(category, 'id')
);


export const getCurrentCategoryFromLinkWithStructure = createSelector(
    getCurrentCategoryFromLink,
    category => ({
        currentCategory: category || {}
    })

);

export const getClassifiedList = createSelector(
    [state => state.categories.ppsList],
    list => list
);

export const getAllPp = createSelector(
    [state => state.categories.pp],
    pp => pp
);


export const getCategorySlugsByLink = createSelector(
    getCategoriesBySlug,
    getCategoriesById,
    (state, props) => props.match,
    (categoriesBySlug, categoriesById, match) => {
        let categoriesFromLink = checkNested(match, ['params', 0]) || '';
        categoriesFromLink = categoriesFromLink.split('/');
        const categorySlug = categoriesFromLink[0];
        const subcategorySlug = categoriesFromLink[1];
        const categories = [categorySlug, subcategorySlug];
        const currentCategoryId = categoriesBySlug[removeLastSlash(categories.join('/'))];
        return checkNested(categoriesById[currentCategoryId], 'slug');
    }
);

export const getInfiniteClassifiedList = createSelector(
    getClassifiedList,
    list => list[`${window.location.pathname}_${PP_TYPE.infinite}`] || emptyObject
);

export const getInfiniteClassifiedCountSelector = createSelector(
    countState,
    countInList => get(countInList, [PP_TYPE.infinite, `${window.location.pathname}_${PP_TYPE.infinite}`], null)
);

export const getRootCategoriesSelector = createSelector(
    state => state.categories && state.categories.categories.root,
    categories => keyBy(categories, 'id')
);


export const getCategoriesFullTreeSelector = createSelector(
    state => state.categories.categoriesFullTree,
    categoriesFullTree => categoriesFullTree
);


export const getRootCategoriesArraySelector = createSelector(
    state => state.categories && state.categories.categories.root,
    categories => categories
);


export const getCategoryTreeByRootId = createSelector(
    state => state.categories.categoriesFullTree,
    (state, category) => category,
    (state, category, lang) => lang,
    (categoriesFullTree, category, lang) => checkNested(keyBy(categoriesFullTree[lang], 'id'), category)
);


export const getHomeDisabledCategoriesSelector = createSelector(
    state => state.home.homeDisabledCategories,
    categories => categories
);


export const categoriesGetParentListSelector = createSelector(
    state => state.categories.categories.byId,
    state => state.categories.categories.root,
    (state, props) => props.category,
    (state, props) => props.isRoot,
    (state, props) => props.useLastCategory,
    (state, props) => props.parentId,
    (categories, rootCategories, category, isRoot, useLastCategory, parentId) => {
        if (isRoot) {
            return rootCategories;
        }
        const parentCat = checkNested(categories, [parentId]);
        if (parentCat) {
            return map(checkNested(parentCat, 'children'), o => ({ ...o, categoryTree: checkNested(categories, [o.id, 'categoryTree']) }));
        }

        return (map(checkNested(categories, [checkNested(categories, [category, 'parentId']) || useLastCategory, 'children'], []), o => ({ ...o, categoryTree: checkNested(categories, [o.id, 'categoryTree']) })));
    }
);

export const lastCategoryChildrenSortedByHighlight = createSelector(
    state => state.categories.categories.byId,
    (state, props) => props.category,
    (state, props) => props.useLastCategory,

    (state, props) => props.parentId,
    (categories, category, useLastCategory, parentId) => {
        let categoryFromState = checkNested(categories, [checkNested(categories, [category, 'parentId']) || useLastCategory, 'children'], []);
        const parentCat = checkNested(categories, [parentId]);
        if (parentId && parentCat) {
            categoryFromState = parentCat.children;
        }

        return map(categoryFromState, o => ({ ...o, categoryTree: checkNested(categories, [o.id, 'categoryTree']) }));
    }
);

export const lastCategoryHighlight = createSelector(
    state => state.categories.categories.byId,
    (state, props) => props.category,
    (state, props) => props.useLastCategory,

    (state, props) => props.parentId,
    (categories, category, useLastCategory, parentId) => {
        let categoryFromState = checkNested(categories, [checkNested(categories, [category, 'parentId']) || useLastCategory, 'children'], []);
        const parentCat = checkNested(categories, [parentId]);
        if (parentId && parentCat) {
            categoryFromState = parentCat.children;
        }

        return sortCategoriesByHighlight(map(categoryFromState, o => ({ ...o, categoryTree: checkNested(categories, [o.id, 'categoryTree']) })), true);
    }
);

export const getCategoriesFullTree = createSelector(
    state => state.categories.categoriesFullTree,
    state => state.general.selectedLang,
    (categoriesFullTree, lang) => categoriesFullTree[lang]
);

export const categoriesGetPpByIdSelector = createSelector(
    [
        state => state.categories.pp,
        (state, pp) => pp
    ],
    (ppList, pp) => ppList[pp]
);


export const categoriesLotMembersSelector = createSelector(
    [
        state => state.categories.lotMembers
    ],
    (lotMembers) => lotMembers
);

export const getPopularRegionsTree = createSelector(
    state => state.categories.popularRegionsTree,
    state => state.general.selectedLang,
    (popularRegionsTree, lang) => popularRegionsTree[lang]
);

export const getPopularRegionsTreeByIdSelector = createSelector(
    getPopularRegionsTree,
    regions => keyBy(regions, 'category.id')
);

export const getLotPosition = createSelector(
    state => state.categories.lotPosition,
    list => list
);

export const getCategoryHints = createSelector(
    state => state.categories.hints,
    hints => hints
);
